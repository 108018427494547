<template>
  <b-card-code>
    <div v-if="$can('generate','Salary')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="salaries"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'employee_id'">
          {{ getEmployeeId(intConvert(props.row.employee_id)) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{ employeeNameById(intConvert(props.row.employee_id)) }}
        </span>
        <span v-else-if="props.column.field === 'designation_scale_id'">
          {{ designationScaleByID(intConvert(props.row.designation_scale_id)) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(intConvert(props.row.academic_year_id)) }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?$t('Add new'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="add_or_update_form">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Salary Month') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('salary month')"
                    rules="required"
                >
                  <b-form-input
                      v-model="month_year" type="month"
                      name="month_year"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('yyyy-mm')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select academic year') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('academic year')"
                    rules="required"
                >
                  <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Working day in month') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('working day in month')"
                    rules="required"
                >
                  <b-form-input type="number"
                      v-model.number="total_day_in_month"
                      name="total_day_in_month"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('number of working day in month')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Generate Salary
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BCardText,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name:'EmployeeSalary',
  components: {
    BCardCode,
    VueGoodTable,
    BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      total_day_in_month: 0,
      month_year:null,
      academic_year_id:null,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Employee Id',
          field: 'employee_id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Grade',
          field: 'designation_scale_id',
        },
        {
          label: 'Salary month',
          field: 'month_year',
        },
        {
          label: 'Total Day in Month',
          field: 'total_day_in_month',
        },
        {
          label: 'Present In month',
          field: 'present_in_month',
        },
        {
          label: 'Absent In month',
          field: 'absent_in_month',
        },
        {
          label: 'Basic salary',
          field: 'basic_salary',
        },
        {
          label: 'Cut Basic salary',
          field: 'cut_basic_salary',
        },
        {
          label: 'House Rent',
          field: 'house_rent',
        },
        {
          label: 'Cut House Rent',
          field: 'cut_house_rent',
        },
        {
          label: 'Medical Allowance',
          field: 'medical_allowance',
        },
        {
          label: 'Cut Medical Allowance',
          field: 'cut_medical_allowance',
        },
        {
          label: 'Transport Cost',
          field: 'transport_cost',
        },
        {
          label: 'Cut Transport Cost',
          field: 'cut_transport_cost',
        },
        {
          label: 'Provident Fund Amount',
          field: 'provident_fund_amount',
        },
        {
          label: 'Pay Loan Amount',
          field: 'pay_loan_amount',
        },
        {
          label: 'Bonus Amount',
          field: 'bonus_amount',
        },
        {
          label: 'Gratuity',
          field: 'gratuity',
        },
        {
          label: 'Total Salary',
          field: 'total_salary',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      columns10: [
        {
          label: 'Employee Id',
          field: 'EmployeeId',
        },
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Grade',
          field: 'Grade',
        },
        {
          label: 'Salary month',
          field: 'SalaryMonth',
        },
        {
          label: 'Total Day in Month',
          field: 'TotalDayInMonth',
        },
        {
          label: 'Present In month',
          field: 'PresentInMonth',
        },
        {
          label: 'Absent In month',
          field: 'AbsentInMonth',
        },
        {
          label: 'Basic salary',
          field: 'BasicSalary',
        },
        {
          label: 'Cut Basic salary',
          field: 'CutBasicSalary',
        },
        {
          label: 'House Rent',
          field: 'HouseRent',
        },
        {
          label: 'Cut House Rent',
          field: 'CutHouseRent',
        },
        {
          label: 'Medical Allowance',
          field: 'MedicalAllowance',
        },
        {
          label: 'Cut Medical Allowance',
          field: 'CutMedicalAllowance',
        },
        {
          label: 'Transport Cost',
          field: 'TransportCost',
        },
        {
          label: 'Cut Transport Cost',
          field: 'CutTransportCost',
        },
        {
          label: 'Provident Fund Amount',
          field: 'ProvidentFundAmount',
        },
        {
          label: 'Pay Loan Amount',
          field: 'PayLoanAmount',
        },
        {
          label: 'Bonus Amount',
          field: 'BonusAmount',
        },
        {
          label: 'Bonus Percent',
          field: 'BonusPercent',
        },
        {
          label: 'Gratuity',
          field: 'Gratuity',
        },
        {
          label: 'Total Salary',
          field: 'TotalSalary',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
      ],
    }
  },
  methods:{
    intConvert(dd){
      try {
        return parseInt(dd);
      }catch (e){
        return dd;
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_form);
          if (this.model_mode ==='add'){
            apiCall.post('/employee/salary/generate',data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_SALARY');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }/*else {
            apiCall.post(`/employee/bonus/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_BONUS');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }*/
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.month_year=null;
      this.academic_year_id=null;
      this.total_day_in_month=0;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    pdfGenerate() {
      let title = "Salary";
      let clm = ['EmployeeId','Name','Grade','SalaryMonth','TotalDayInMonth','PresentInMonth','AbsentInMonth','BasicSalary','CutBasicSalary','HouseRent','CutHouseRent','MedicalAllowance','CutMedicalAllowance','TransportCost','CutTransportCost','ProvidentFundAmount','PayLoanAmount','BonusAmount','BonusPercent','Gratuity','TotalSalary','AcademicYear' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/commom/landscape/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'salary_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['salaries','designation_scales','designationScaleByID','bonus_types','bonusTypeByID', 'getEmployeeId',
      'academic_years','academic_yearByID','employees','employeeIdById','employeeNameById']),
    getData() {
      return this.salaries.map(item => ({
        EmployeeId: item.employee_id ? this.employeeIdById(item.employee_id) : '',
        Name: item.employee_id ? this.employeeNameById(item.employee_id) : '',
        Grade: item.designation_scale_id ? this.designationScaleByID(item.designation_scale_id) : '',
        SalaryMonth: item.month_year,
        TotalDayInMonth: item.total_day_in_month ? item.total_day_in_month : 0,
        PresentInMonth: item.present_in_month ? item.present_in_month : 0,
        AbsentInMonth: item.absent_in_month? item.absent_in_month : 0,
        BasicSalary: item.basic_salary,
        CutBasicSalary: item.cut_basic_salary? item.cut_basic_salary : 0,
        HouseRent: item.house_rent ? item.house_rent : 0,
        CutHouseRent: item.cut_house_rent ? item.cut_house_rent : 0,
        MedicalAllowance: item.medical_allowance ? item.medical_allowance : 0,
        CutMedicalAllowance: item.cut_medical_allowance ? item.cut_medical_allowance : 0,
        TransportCost: item.transport_cost ? item.transport_cost : 0,
        CutTransportCost: item.cut_transport_cost ? item.cut_transport_cost : 0,
        ProvidentFundAmount: item.provident_fund_amount ? item.provident_fund_amount : 0,
        PayLoanAmount: item.pay_loan_amount ? item.pay_loan_amount : 0,
        BonusAmount: item.bonus_amount ? item.bonus_amount:  0,
        BonusPercent: item.bonus_percent ? item.bonus_percent: 0,
        Gratuity: item.gratuity ? item.gratuity : 0,
        TotalSalary: item.total_salary,
        AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
      }));
    }
  },
  created() {
    if(this.salaries.length<1) this.$store.dispatch('GET_ALL_EMPLOYEE_SALARY');
    if(this.employees.length<1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    if(this.designation_scales.length<1) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if(this.bonus_types.length<1) this.$store.dispatch('GET_ALL_BONUS_TYPE');
    if(this.academic_years.length<1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>